import React, { useState, useEffect, useRef, useContext } from "react"
import { Link } from "gatsby"

import { SocialLinks } from "../anti/social-links/social-links"

import { LangContext } from "../../context/LangContext"

import {
  staggerBackgroundIn,
  staggerBackgroundOut,
  staggerContentIn,
  staggerContentOut,
  data,
} from "./animations"
import MobileSubmenu from "./mobile-submenu"
import MobileAboutSubmenu from "./mobile-about-submenu"
import MobileServicesSubmenu from "./mobile-services"

const MobileMenu = ({
  mobileMenu,
  navExpand,
  data,
  handleMenuMobile,
  newsSlug,
  isAmp,
}) => {
  const { lang, setLang } = useContext(LangContext)

  // MOBILE SUBMENU - State & handler
  // Open / close submenu state & handler
  const [mobileSubmenu, setMobileSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [mobileAboutSubmenu, setMobileAboutSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [mobileServicesSubmenu, setMobileServicesSubmenu] = useState({
    initial: false,
    clicked: null,
  })

  // const setToID = () => {
  //   sessionStorage.setItem("bsd-lang", "ID")
  //   setLang("ID")
  //   handleMenuMobile()
  // }
  // const setToEN = () => {
  //   sessionStorage.setItem("bsd-lang", "EN")
  //   setLang("EN")
  //   handleMenuMobile()
  // }

  const changeLanguage = language => {
    sessionStorage.setItem("bsd-lang", language === "ID" ? "EN" : "ID")

    const currentPath = window.location.pathname
    let LangPath = currentPath.split("/")

    if (language === "ID" && LangPath[1] !== "id") {
      LangPath.splice(1, 0, "id")
    } else if (language === "EN" && LangPath[1] === "id") {
      LangPath.splice(1, 1)
    }

    LangPath = LangPath.join("/")
    if (currentPath !== LangPath) {
      if (newsSlug && language === "EN") {
        window.location.replace(`/news-events/${newsSlug}`)
      } else if (newsSlug && language === "ID") {
        window.location.replace(`/id/news-events/${newsSlug}`)
      } else {
        window.location.replace(LangPath || "/")
      }
      setLang(language)
    }
  }

  const handleMobileSubmenu = () => {
    disableSubmenuToggler()
    if (mobileSubmenu.initial === false) {
      setMobileSubmenu({ initial: null, clicked: true })
    } else if (mobileSubmenu.clicked === true) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    } else if (mobileSubmenu.clicked === false) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    }
  }
  const handleMobileAboutSubmenu = () => {
    disableSubmenuAboutToggler()
    if (mobileAboutSubmenu.initial === false) {
      setMobileAboutSubmenu({ initial: null, clicked: true })
    } else if (mobileAboutSubmenu.clicked === true) {
      setMobileAboutSubmenu({ clicked: !mobileAboutSubmenu.clicked })
    } else if (mobileAboutSubmenu.clicked === false) {
      setMobileAboutSubmenu({ clicked: !mobileAboutSubmenu.clicked })
    }
  }
  const handleMobileServicesSubmenu = () => {
    disableSubmenuServicesToggler()
    if (mobileServicesSubmenu.initial === false) {
      setMobileServicesSubmenu({ initial: null, clicked: true })
    } else if (mobileServicesSubmenu.clicked === true) {
      setMobileServicesSubmenu({ clicked: !mobileServicesSubmenu.clicked })
    } else if (mobileServicesSubmenu.clicked === false) {
      setMobileServicesSubmenu({ clicked: !mobileServicesSubmenu.clicked })
    }
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuAboutToggler, setSubmenuAboutToggler] = useState(false)
  const disableSubmenuAboutToggler = () => {
    setSubmenuAboutToggler(!submenuAboutToggler)
    setTimeout(() => {
      setSubmenuAboutToggler(false)
    }, 1000)
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuServicesToggler, setSubmenuServicesToggler] = useState(false)
  const disableSubmenuServicesToggler = () => {
    setSubmenuServicesToggler(!submenuServicesToggler)
    setTimeout(() => {
      setSubmenuServicesToggler(false)
    }, 1000)
  }

  // MOBILE MENU - Animation
  // Element ref.
  let menuWrapper = useRef(null) // Reveal 1
  let menuBackground = useRef(null) // Reveal 2
  let menuContent = useRef(null) // Reveal 3
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)
  let line9 = useRef(null)
  let line10 = useRef(null)
  // Reveal & close animation
  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler")
    const mainElmnt = document.querySelector("main")
    if (mobileMenu.clicked === false) {
      staggerContentOut(
        line10,
        line9,
        line8,
        line7,
        line6,
        line5,
        line4,
        line3,
        line2,
        line1
      )
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
      navbarToggler.classList.remove("open", "dark")
      mainElmnt.classList.remove("overflow-hidden")
    } else if (
      mobileMenu.clicked === true ||
      (mobileMenu.clicked === true && mobileMenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      staggerContentIn(
        line1,
        line2,
        line3,
        line4,
        line5,
        line6,
        line7,
        line8
        // line9,
        // line10
      )
      navbarToggler.classList.add("open", "dark")
      setTimeout(() => mainElmnt.classList.add("overflow-hidden"), 200)
    }
  }, [mobileMenu])

  return (
    <>
      {isAmp ? (
        <>
          <amp-sidebar id="sidebar1" layout="nodisplay" side="right">
            <div
              role="button"
              aria-label="close sidebar"
              on="tap:sidebar1.toggle"
              tabindex="0"
              class="close-sidebar-right"
            >
              ✕
            </div>
            <ul class="sidebar">
              {data.wordPress.menus.nodes.map((menuSections, i) => {
                if (lang === "EN" && menuSections.slug === "navbar-menu") {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("about")
                    ) {
                      return (
                        <li key={`${menu.label.replace(/\s/g, "")}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label.replace(
                              /\s/g,
                              ""
                            )}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("development")
                    ) {
                      return (
                        <li key={`${menu.label}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("services")
                    ) {
                      return (
                        <li key={`${menu.label}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li>
                        <a
                          href={
                            menu.label.toLowerCase().includes("home")
                              ? menu.url
                              : menu.url + "/"
                          }
                        >
                          {menu.label}
                        </a>
                      </li>
                    )
                  })
                } else if (
                  lang === "ID" &&
                  (menuSections.slug === "navbar-menu-indonesia" ||
                    menuSections.slug === "navbar-menu-id")
                ) {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("tentang")
                    ) {
                      return (
                        <li key={`${menu.label}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      (menu.label.toLowerCase().includes("embangan") ||
                        menu.label.toLowerCase().includes("pembangun"))
                    ) {
                      return (
                        <li key={`${menu.label}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    } else if (
                      (menu.url === "#" &&
                        menu.label.toLowerCase().includes("layanan")) ||
                      menu.label.toLowerCase().includes("jasa")
                    ) {
                      return (
                        <li key={`${menu.label}${j}`}>
                          <div
                            role="button"
                            on={`tap:side${menu.label}.toggle`}
                            tabindex="0"
                            class="sub-sidebar"
                          >
                            {menu.label}
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li>
                        <a
                          href={
                            menu.label.toLowerCase().includes("home")
                              ? menu.url
                              : menu.url + "/"
                          }
                        >
                          {menu.label}
                        </a>
                      </li>
                    )
                  })
                }
              })}
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line1 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line1 = el)}></div>
                  } else {
                    return <div ref={el => (line1 = el)}></div>
                  }
                })}
              </li>
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line2 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line2 = el)}></div>
                  } else {
                    return <div ref={el => (line2 = el)}></div>
                  }
                })}
              </li>
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line3 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line3 = el)}></div>
                  } else {
                    return <div ref={el => (line3 = el)}></div>
                  }
                })}
              </li>
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line4 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line4 = el)}></div>
                  } else {
                    return <div ref={el => (line4 = el)}></div>
                  }
                })}
              </li>
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line5 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line5 = el)}></div>
                  } else {
                    return <div ref={el => (line5 = el)}></div>
                  }
                })}
              </li>
              <li>
                {data.wordPress.menus.nodes.map((item, i) => {
                  if (lang == "EN" && item.slug == "navbar-menu") {
                    return <div ref={el => (line6 = el)}></div>
                  } else if (
                    lang == "ID" &&
                    (item.slug == "navbar-menu-indonesia" ||
                      item.slug === "navbar-menu-id")
                  ) {
                    return <div ref={el => (line6 = el)}></div>
                  } else {
                    return <div ref={el => (line6 = el)}></div>
                  }
                })}
              </li>
              <li className="nav-item">
                <div ref={el => (line9 = el)}>
                  {/* prettier-ignore */}
                  <Link className="nav-link" activeClassName="active" to="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_BSD_City_Website_Menu" target="_blank" >
                    E-Catalog
                  </Link>
                </div>
              </li>
              {/* <li className="nav-item">
                <div ref={el => (line10 = el)}>
                  <Link className="nav-link" activeClassName="active" to="/kek">
                    KEK
                  </Link>
                </div>
              </li> */}
              <li className="nav-item">
                <div ref={el => (line10 = el)} style={{ position: "relative" }}>
                  <div
                    className="bg-primary"
                    style={{
                      position: "absolute",
                      right: 0,
                      borderRadius: 12,
                      fontSize: 8,
                      color: "white",
                      paddingLeft: 6,
                      paddingRight: 6,
                    }}
                  >
                    NEW
                  </div>
                  {/* prettier-ignore */}
                  <Link className="nav-link" activeClassName="active" to="/kek">
                    KEK
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="sidebar-additional">
              <li>
                <Link
                  className="btn btn-secondary btn-block btn-mobile-login text-capitalize"
                  to="/login/"
                >
                  {lang === "ID" ? "Masuk" : "Login"}
                </Link>
              </li>
            </ul>
            <SocialLinks data={data.wordPress.menus.nodes} isAmp={isAmp} />
            <ul className="language-wrapper">
              <li>
                <div className="toggle-dual-languages">
                  <div
                    className={`toggle-item ${lang == "EN" && "active"}`}
                    onClick={() => changeLanguage("EN")}
                  >
                    <p>ENG</p>
                  </div>
                  <div
                    className={`toggle-item ${lang == "ID" && "active"}`}
                    onClick={() => changeLanguage("ID")}
                  >
                    <p>IND</p>
                  </div>
                </div>
              </li>
            </ul>
          </amp-sidebar>
          <MobileAboutSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileAboutSubmenu}
            navExpand={navExpand}
            data={data}
            lang={lang}
            isAmp={isAmp}
          />
          <MobileSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileSubmenu}
            navExpand={navExpand}
            lang={lang}
            datalist={data}
            isAmp={isAmp}
          />
          <MobileServicesSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileServicesSubmenu}
            navExpand={navExpand}
            data={data}
            lang={lang}
            isAmp={isAmp}
          />
        </>
      ) : (
        <>
          <div
            className={`navbar-menu light d-${navExpand}-none`}
            ref={el => (menuWrapper = el)}
          >
            <div
              className="navbar-menu-bg"
              ref={el => (menuBackground = el)}
            ></div>
            <div className="navbar-menu-content" ref={el => (menuContent = el)}>
              <div className="navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  {data.wordPress.menus.nodes.map((menuSections, i) => {
                    // Navbar ENGLISH
                    if (lang === "EN" && menuSections.slug === "navbar-menu") {
                      return menuSections.menuItems.nodes.map((menu, j) => {
                        if (
                          menu.url === "#" &&
                          menu.label.toLowerCase().includes("about")
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuAboutToggler}
                                  onClick={() => {
                                    handleMobileAboutSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        } else if (
                          menu.url === "#" &&
                          menu.label.toLowerCase().includes("development")
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuToggler}
                                  onClick={() => {
                                    handleMobileSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        } else if (
                          menu.url === "#" &&
                          menu.label.toLowerCase().includes("services")
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuServicesToggler}
                                  onClick={() => {
                                    handleMobileServicesSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        }
                        return (
                          <li className={`nav-item`}>
                            <div>
                              <Link
                                className="nav-link"
                                activeClassName="active"
                                to={
                                  menu.label.toLowerCase().includes("home")
                                    ? menu.url
                                    : menu.url + "/"
                                }
                                key={`${menu.label}-${j}`}
                              >
                                {menu.label}
                              </Link>
                            </div>
                          </li>
                          // <div ref={el => (line7 = el)}></div>
                        )
                      })
                      // Navbar INDO
                    } else if (
                      lang === "ID" &&
                      (menuSections.slug === "navbar-menu-indonesia" ||
                        menuSections.slug === "navbar-menu-id")
                    ) {
                      return menuSections.menuItems.nodes.map((menu, j) => {
                        if (
                          menu.url === "#" &&
                          menu.label.toLowerCase().includes("tentang")
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuAboutToggler}
                                  onClick={() => {
                                    handleMobileAboutSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        } else if (
                          menu.url === "#" &&
                          (menu.label.toLowerCase().includes("embangan") ||
                            menu.label.toLowerCase().includes("pembangun"))
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuToggler}
                                  onClick={() => {
                                    handleMobileSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        } else if (
                          (menu.url === "#" &&
                            menu.label.toLowerCase().includes("layanan")) ||
                          menu.label.toLowerCase().includes("jasa")
                        ) {
                          return (
                            <li
                              className={`nav-item`}
                              key={`${menu.label}${j}`}
                            >
                              <div>
                                <button
                                  className="nav-link dropdown-toggle"
                                  disabled={submenuServicesToggler}
                                  onClick={() => {
                                    handleMobileServicesSubmenu()
                                  }}
                                >
                                  {menu.label}
                                </button>
                              </div>
                            </li>
                          )
                        }
                        return (
                          <li className={`nav-item`}>
                            <div>
                              <Link
                                className="nav-link"
                                activeClassName="active"
                                to={
                                  menu.label.toLowerCase().includes("beranda")
                                    ? menu.url
                                    : menu.url + "/"
                                }
                                key={`${menu.label}-${j}`}
                              >
                                {menu.label}
                              </Link>
                            </div>
                          </li>
                          // <div ref={el => (line7 = el)}></div>
                        )
                      })
                    }
                  })}
                  <li className="nav-item">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line1 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line1 = el)}></div>
                      } else {
                        return <div ref={el => (line1 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item dropdown">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line2 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line2 = el)}></div>
                      } else {
                        return <div ref={el => (line2 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item dropdown">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line3 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line3 = el)}></div>
                      } else {
                        return <div ref={el => (line3 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line4 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line4 = el)}></div>
                      } else {
                        return <div ref={el => (line4 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line5 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line5 = el)}></div>
                      } else {
                        return <div ref={el => (line5 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line6 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line6 = el)}></div>
                      } else {
                        return <div ref={el => (line6 = el)}></div>
                      }
                    })}
                  </li>
                  <li className="nav-item">
                    {data.wordPress.menus.nodes.map((item, i) => {
                      if (lang == "EN" && item.slug == "navbar-menu") {
                        return <div ref={el => (line7 = el)}></div>
                      } else if (
                        lang == "ID" &&
                        (item.slug == "navbar-menu-indonesia" ||
                          item.slug === "navbar-menu-id")
                      ) {
                        return <div ref={el => (line7 = el)}></div>
                      } else {
                        return <div ref={el => (line7 = el)}></div>
                      }
                    })}
                  </li>
                  {/* <li className="nav-item">
                    <div ref={el => (line10 = el)}>
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/kek"
                      >
                        KEK
                      </Link>
                    </div>
                  </li> */}
                  <li className="nav-item">
                    <div
                      ref={el => (line10 = el)}
                      style={{ position: "relative" }}
                    >
                      <div
                        className="bg-primary"
                        style={{
                          position: "absolute",
                          left: 50,
                          top: 10,
                          borderRadius: 12,
                          fontSize: 8,
                          color: "white",
                          paddingLeft: 6,
                          paddingRight: 6,
                        }}
                      >
                        NEW
                      </div>

                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/kek"
                      >
                        KEK
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div ref={el => (line9 = el)}>
                      {/* prettier-ignore */}
                      <Link className="nav-link" activeClassName="active" to="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_BSD_City_Website_Menu" target="_blank" >
                        E-Catalog
                      </Link>
                    </div>
                  </li>
                </ul>
                <div className="social-links-wrapper">
                  <div ref={el => (line8 = el)}>
                    <SocialLinks data={data.wordPress.menus.nodes} />
                  </div>
                  <div className="toggle-dual-languages">
                    <div
                      className={`toggle-item ${lang == "EN" && "active"}`}
                      onClick={() => changeLanguage("EN")}
                    >
                      <p>ENG</p>
                    </div>
                    <div
                      className={`toggle-item ${lang == "ID" && "active"}`}
                      onClick={() => changeLanguage("ID")}
                    >
                      <p>IND</p>
                    </div>
                  </div>
                </div>
                <Link
                  className="btn btn-secondary btn-block btn-mobile-login text-capitalize"
                  to="/login"
                >
                  {lang === "ID" ? "Masuk" : "Login"}
                </Link>
              </div>
            </div>
          </div>
          <MobileSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileSubmenu}
            handleMobileSubmenu={handleMobileSubmenu}
            navExpand={navExpand}
            lang={lang}
          />
          <MobileAboutSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileAboutSubmenu}
            handleMobileSubmenu={handleMobileAboutSubmenu}
            navExpand={navExpand}
            data={data}
            lang={lang}
          />
          <MobileServicesSubmenu
            menusMobile={data.wordPress.menus.nodes.filter(el =>
              el.slug.includes("navbar-menu")
            )}
            mobileSubmenu={mobileServicesSubmenu}
            handleMobileSubmenu={handleMobileServicesSubmenu}
            navExpand={navExpand}
            data={data}
            lang={lang}
          />
        </>
      )}
    </>
  )
}

export default MobileMenu
