import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import { LangContext } from "../../context/LangContext"

import DesktopSubmenu from "./desktop-submenu"
import DesktopAboutSubmenu from "./desktop-about-submenu"
import DesktopServicesSubmenu from "./desktop-services"

const DesktopMenu = ({
  navExpand,
  megaMenu,
  setMegaMenu,
  data,
  setNavScroll,
}) => {
  const { lang, setLang } = useContext(LangContext)
  // DESKTOP SUBMENU - State & handler
  // Open / close submenu state & handler
  const [desktopSubmenu, setDesktopSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [desktopAboutSubmenu, setDesktopAboutSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [desktopServicesSubmenu, setDesktopServicesSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [search, setSearch] = useState(false)
  const clickSearch = () => {
    setSearch(!search)
    setNavScroll(true)
  }
  const handleDesktopSubmenu = arg => {
    disableSubmenuToggler()

    // if (desktopSubmenu.clicked || desktopAboutSubmenu.clicked) {
    //   setMegaMenu(true)
    // } else if (!desktopAboutSubmenu.clicked && !desktopSubmenu.clicked) {
    //   setMegaMenu(false)
    // }

    if (arg !== arg) {
      setDesktopSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopSubmenu.clicked === true) {
      setDesktopSubmenu({ clicked: false })
      setDesktopAboutSubmenu({ clicked: false })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopSubmenu.clicked === false) {
      setDesktopSubmenu({ clicked: true, type: arg })
      setDesktopAboutSubmenu({ clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    }
  }

  const handleAboutDesktopSubmenu = arg => {
    disableAboutSubmenuToggler()

    // if (desktopSubmenu.clicked || desktopAboutSubmenu.clicked) {
    //   setMegaMenu(true)
    // } else if (!desktopAboutSubmenu.clicked && !desktopSubmenu.clicked) {
    //   setMegaMenu(true)
    // }

    // setMegaMenu(!megaMenu)
    if (arg !== arg) {
      setDesktopAboutSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopAboutSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopAboutSubmenu.clicked === true) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopAboutSubmenu.clicked === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    }
  }

  const handleServicesDesktopSubmenu = arg => {
    disableServicesSubmenuToggler()

    // if (desktopSubmenu.clicked || desktopAboutSubmenu.clicked) {
    //   setMegaMenu(true)
    // } else if (!desktopAboutSubmenu.clicked && !desktopSubmenu.clicked) {
    //   setMegaMenu(true)
    // }

    // setMegaMenu(!megaMenu)
    if (arg !== arg) {
      setDesktopServicesSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopServicesSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: true, type: arg })
    } else if (desktopServicesSubmenu.clicked === true) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopServicesSubmenu.clicked === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: true, type: arg })
    }
  }

  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }

  const [aboutSubmenuToggler, setAboutSubmenuToggler] = useState(false)
  const disableAboutSubmenuToggler = () => {
    setAboutSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setAboutSubmenuToggler(false)
    }, 1000)
  }

  const [servicesSubmenuToggler, setServicesSubmenuToggler] = useState(false)
  const disableServicesSubmenuToggler = () => {
    setServicesSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setServicesSubmenuToggler(false)
    }, 1000)
  }

  const setToID = () => {
    sessionStorage.setItem("bsd-lang", "ID")
    setLang("ID")
  }
  const setToEN = () => {
    sessionStorage.setItem("bsd-lang", "EN")
    setLang("EN")
  }

  return (
    <>
      <div className={`navbar-menu light d-${navExpand}-block`}>
        <div className="navbar-menu-bg"></div>
        <div className="navbar-menu-content">
          <div className="navbar-collapse">
            <ul className="navbar-nav ml-auto">
              {data.wordPress.menus.nodes.map((menuSections, i) => {
                // Navbar ENGLISH
                if (lang === "EN" && menuSections.slug === "navbar-menu") {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("about")
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={aboutSubmenuToggler}
                              onClick={() => {
                                handleAboutDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("development")
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}-${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuToggler}
                              onClick={() => {
                                handleDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("services")
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}-${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={servicesSubmenuToggler}
                              onClick={() => {
                                handleServicesDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li className={`nav-item ${search && "active-search"}`}>
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={
                              menu.label.toLowerCase().includes("home")
                                ? menu.url
                                : menu.url + "/"
                            }
                            key={`${menu.label}-${j}`}
                          >
                            {menu.label}
                          </Link>
                        </div>
                      </li>
                    )
                  })
                  // Navbar INDO
                } else if (
                  lang === "ID" &&
                  (menuSections.slug === "navbar-menu-indonesia" ||
                    menuSections.slug === "navbar-menu-id")
                ) {
                  return menuSections.menuItems.nodes.map((menu, j) => {
                    if (
                      menu.url === "#" &&
                      menu.label.toLowerCase().includes("tentang")
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={aboutSubmenuToggler}
                              onClick={() => {
                                handleAboutDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      menu.url === "#" &&
                      (menu.label.toLowerCase().includes("embangan") ||
                        menu.label.toLowerCase().includes("pembangun"))
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}-${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={submenuToggler}
                              onClick={() => {
                                handleDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    } else if (
                      (menu.url === "#" &&
                        menu.label.toLowerCase().includes("layanan")) ||
                      menu.label.toLowerCase().includes("jasa")
                    ) {
                      return (
                        <li
                          className={`nav-item ${search && "active-search"}`}
                          key={`${menu.label}-${j}`}
                        >
                          <div>
                            <button
                              className="nav-link dropdown-toggle"
                              disabled={servicesSubmenuToggler}
                              onClick={() => {
                                handleServicesDesktopSubmenu()
                              }}
                            >
                              {menu.label}
                            </button>
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li className={`nav-item ${search && "active-search"}`}>
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={
                              menu.label.toLowerCase().includes("beranda")
                                ? menu.url
                                : menu.url + "/"
                            }
                            key={`${menu.label}-${j}`}
                          >
                            {menu.label}
                          </Link>
                        </div>
                      </li>
                    )
                  })
                }
              })}
              <li className={`nav-item ${search && "active-search"}`}>
                <div>
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_BSD_City_Website_Menu"
                    target="_blank"
                  >
                    E-Catalog
                  </Link>
                </div>
              </li>
              {/* <li className={`nav-item ${search && "active-search"}`}>
                <div>
                  <Link className="nav-link" activeClassName="active" to="/kek">
                    KEK
                  </Link>
                </div>
              </li> */}
              <li className={`nav-item ${search && "active-search"}`}>
                <div style={{ position: "relative" }}>
                  <div
                    className="bg-primary"
                    style={{
                      position: "absolute",
                      right: -10,
                      top: -2,
                      borderRadius: 12,
                      fontSize: 8,
                      color: "white",
                      paddingLeft: 6,
                      paddingRight: 6,
                    }}
                  >
                    NEW
                  </div>
                  {/* prettier-ignore */}
                  <Link className="nav-link" activeClassName="active" to="/kek">
                    KEK
                  </Link>
                </div>
              </li>
              <li className={`nav-item`}>
                <div>
                  <form
                    action={`${lang === "EN" ? "" : "/id"}/search-result`}
                    className={`form-search ${search && "is-open"}`}
                  >
                    <input
                      className="form-control"
                      name="key"
                      id="key"
                      type="text"
                      placeholder={lang == "EN" ? "Search" : "Cari"}
                    />
                  </form>
                </div>
              </li>
              <li className="nav-item" onClick={clickSearch}>
                <div>
                  {/* prettier-ignore */}
                  <button className="nav-link nav-link-icon" aria-label="toggler-search">
                    <i className="fal fa-search" />
                  </button>
                </div>
              </li>
              <li className="nav-item nav-desktop ml-1 d-flex align-items-center">
                <Link
                  className="btn btn-secondary pb-1 pt-1 login text-capitalize"
                  to="/login/"
                >
                  {lang === "ID" ? "Masuk" : "Login"}
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center">
                <div className="">
                  {/* <div className="toggle-dual-languages">
                    <div className={`toggle-item ${lang == "EN" && "active"}`}  onClick={setToEN}>
                      <p>ENG</p>
                    </div>
                    <div className={`toggle-item ${lang == "ID" && "active"}`}  onClick={setToID}>
                      <p>IND</p>
                    </div>
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DesktopSubmenu
        menus={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        desktopSubmenu={desktopSubmenu}
        handleDesktopSubmenu={handleDesktopSubmenu}
        navExpand={navExpand}
        lang={lang}
      />
      <DesktopAboutSubmenu
        menus={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        desktopSubmenu={desktopAboutSubmenu}
        handleDesktopSubmenu={handleAboutDesktopSubmenu}
        navExpand={navExpand}
        lang={lang}
        data={
          lang == "EN"
            ? data.wordPress.menus.nodes[2].menuItems.nodes.find(
                x => x.label === "About BSD City"
              )
            : data.wordPress.menus.nodes[3].menuItems.nodes.find(
                x => x.label === "Tentang Kota BSD City"
              )
        }
      />
      <DesktopServicesSubmenu
        menus={data.wordPress.menus.nodes.filter(el =>
          el.slug.includes("navbar-menu")
        )}
        desktopSubmenu={desktopServicesSubmenu}
        handleDesktopSubmenu={handleServicesDesktopSubmenu}
        navExpand={navExpand}
        data={data}
        lang={lang}
      />
    </>
  )
}

export default DesktopMenu
