import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql, navigate } from "gatsby"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import moment from "moment"

import { LangContext } from "../../context/LangContext"

import { SocialLinks } from "../anti/social-links/social-links"

import imgRedRibbon from "../../assets/img/common/img_red-ribbon.png"

const Footer = ({ newsSlug, isAmp }) => {
  const { lang, setLang } = useContext(LangContext)

  const changeLanguage = language => {
    sessionStorage.setItem("bsd-lang", language === "ID" ? "EN" : "ID")

    const currentPath = window.location.pathname
    let LangPath = currentPath.split("/")

    if (language === "ID" && LangPath[1] !== "id") {
      LangPath.splice(1, 0, "id")
    } else if (language === "EN" && LangPath[1] === "id") {
      LangPath.splice(1, 1)
    }

    LangPath = LangPath.join("/")
    if (currentPath !== LangPath) {
      if (newsSlug && language === "EN") {
        navigate(`/news-events/${newsSlug}/`)
      } else if (newsSlug && language === "ID") {
        navigate(`/id/news-events/${newsSlug}/`)
      } else {
        navigate(LangPath || "/")
      }
      setLang(language)
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname.split("/")
    if (currentPath[1] === "id") {
      setLang("ID")
    } else {
      setLang("EN")
    }
  }, [lang])

  const data = useStaticQuery(graphql`
    query {
      wordPress {
        menus {
          nodes {
            name
            slug
            menuItems {
              nodes {
                url
                label
                childItems {
                  nodes {
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const [email, setEmail] = useState()
  const quickLinks = [
    { title: "About Us", link: "/about" },
    { title: "Investor's Area", link: "/investors-area" },
    { title: "Access", link: "/access" },
    { title: "Sitemap", link: "/sitemap" },
    { title: "Blog", link: "/blog" },
  ]
  const estoreData = [
    {
      title: "Blibli",
      link:
        "https://www.blibli.com/merchant/sinar-mas-land-official-store/SIM-70041?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_2021&utm_content=Blibli_Footer_Website_BSDCity",
    },
    {
      title: "Tokopedia",
      link:
        "https://www.tokopedia.com/sinarmasland?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_2021&utm_content=Tokopedia_Footer_Website_BSDCity",
    },
  ]
  return (
    <footer>
      <div className="footer-top py-main pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 footer-col mb-3">
              <div className="col-12">
                <h5>
                  {lang == "EN"
                    ? "Sinar Mas Land Plaza"
                    : "Plaza Sinar Mas Land"}
                </h5>
                <p className="m-0">Sinar Mas Land Plaza</p>
                <p className="mb-3">
                  Jl. Grand Boulevard BSD City, Sampora, Kec. Cisauk,
                  <br />
                  Tangerang, Banten 15345
                </p>
                <p>
                  <a href="tel:+6221-50368368">+6221 - 5036 8368</a>
                </p>
              </div>
              <div className="col-12">
                <h5>
                  {lang == "EN"
                    ? "BSD City Marketing Office"
                    : "BSD City Marketing Office"}
                </h5>
                <p className="mb-3">
                  Jl. Grand Boulevard BSD City, Sampora, Kec. Cisauk,
                  <br />
                  Tangerang, Banten 15345
                </p>
                <p>
                  <a href="tel:+6221-53159000">+6221 - 5315 9000</a>
                </p>
              </div>
            </div>
            <div className="col-md-2 col-12 mb-3">
              <div className="footer-col">
                <h5>{lang == "EN" ? "Quick Links" : "Tautan Penting"}</h5>
                <ul className="footer-nav">
                  {data.wordPress.menus.nodes.map((value, index) => {
                    if (lang == "EN" && value.slug === "footer-menu") {
                      return value.menuItems.nodes.map((value, index) => {
                        return (
                          <li className="footer-item" key={index}>
                            <Link className="footer-link" to={value.url + "/"}>
                              {value.label} <i class="far fa-arrow-right"></i>
                            </Link>
                          </li>
                        )
                      })
                    }
                    if (lang == "ID" && value.slug == "footer-menu-id") {
                      return value.menuItems.nodes.map((value, index) => {
                        return (
                          <li className="footer-item" key={index}>
                            <Link className="footer-link" to={value.url + "/"}>
                              {value.label} <i class="far fa-arrow-right"></i>
                            </Link>
                          </li>
                        )
                      })
                    }
                  })}
                  <li className="footer-item">
                    <Link
                      className="footer-link"
                      to="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_BSD_City_Website_Menu_Footer"
                      target="_blank"
                    >
                      E-Catalog <i class="far fa-arrow-right"></i>
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      className="footer-link"
                      to="/kek"
                    >
                      KEK <i class="far fa-arrow-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-12 mb-3">
              <div className="footer-col">
                <h5>{lang == "EN" ? "Our e-Store" : "e-Store Kami"}</h5>
                <ul className="footer-nav">
                  {estoreData.map((value, index) => {
                    return (
                      <li className="footer-item" key={index}>
                        <Link
                          className="footer-link"
                          to={value.link}
                          target="_blank"
                        >
                          {value.title} <i class="far fa-arrow-right"></i>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-3">
              <div className="footer-col footer-newsletter">
                <h5>Follow Us On</h5>
                {/* <p>
                  {
                    lang == "EN" ? "Subscribe to our newsletter now to get the latest news from BSD City." : "Berlangganan buletin kami sekarang untuk mendapatkan berita terbaru dari BSD City."
                  }
                </p>
                <MailchimpSubscribe
                  url={process.env.MAILCHIMP_URL}
                  render={({ subscribe, status, message }) => {
                    const button = () => {
                      if (status === null) {
                        return (
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => subscribe({ EMAIL: `${email}` })}
                          >
                            <i className="far fa-arrow-right"></i>
                          </button>
                        )
                      } else if (status === "sending") {
                        return (
                          <button
                            disabled
                            className="btn btn-secondary"
                            type="button"
                          >
                            <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        )
                      } else if (status === "success") {
                        return (
                          <button
                            disabled
                            className="btn btn-success"
                            type="button"
                          >
                            <i className="fas fa-check"></i>
                          </button>
                        )
                      } else if (status === "error") {
                        return (
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => subscribe({ EMAIL: `${email}` })}
                          >
                            <i className="far fa-arrow-right"></i>
                          </button>
                        )
                      }
                    }

                    return (
                      <form>
                        <div
                          className={`input-group ${
                            status === "error" ? "" : "mb-3"
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder={lang == "EN" ? "Enter email address" : "Masukan alamat email"}
                            onChange={e => setEmail(e.target.value)}
                          />
                          <div className="input-group-append">{button()}</div>
                        </div>
                        {status === "error" && (
                          <div className="mailchimp-error mb-3">
                            {lang == "EN" ? "email must be a valid email" : "format email harus benar"}
                          </div>
                        )}
                      </form>
                    )
                  }}
                /> */}
                <SocialLinks
                  data={data.wordPress.menus.nodes}
                  className="mt-3"
                  isAmp={isAmp}
                />
                <Copyright lang={lang} className="d-none d-md-block" />
                <div className="toggle-dual-languages mt-3">
                  <div
                    className={`toggle-item py-2 ${lang == "EN" && "active"}`}
                    onClick={() => changeLanguage("EN")}
                  >
                    <p className={lang !== "EN" && "text-gray-400"}>ENG</p>
                  </div>
                  <div
                    className={`toggle-item py-2 ${lang == "ID" && "active"}`}
                    onClick={() => changeLanguage("ID")}
                  >
                    <p className={lang === "EN" && "text-gray-400"}>IND</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  {/* <SocialLinks
                    data={data.wordPress.menus.nodes}
                    className="mt-3"
                /> */}
                  <Copyright lang={lang} className="d-block d-md-none" />
                </div>
                {/* <div className="col-md-2 offset-md-3 d-none d-md-block">
                  <div className="toggle-dual-languages mt-3">
                    <div
                      className={`toggle-item py-2 ${lang == "EN" && "active"}`}
                      onClick={() => changeLanguage("EN")}
                    >
                      <p className={lang !== "EN" && "text-gray-400"}>ENG</p>
                    </div>
                    <div
                      className={`toggle-item py-2 ${lang == "ID" && "active"}`}
                      onClick={() => changeLanguage("ID")}
                    >
                      <p className={lang === "EN" && "text-gray-400"}>IND</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="red-ribbon">
        <div className="ribbon red" />
        <div className="ribbon black" />
        <div className="ribbon black-bottom" />
        {isAmp ? (
          <>
            <amp-img
              src={imgRedRibbon}
              class="img-fluid img-mobile-fluid"
              alt="Red Ribbon"
              width="200"
              height="80"
            ></amp-img>
            <amp-img
              src={imgRedRibbon}
              class="img-fluid img-desktop-fluid"
              alt="Red Ribbon"
              width="248"
              height="90"
            ></amp-img>
          </>
        ) : (
          <img src={imgRedRibbon} className="img-fluid" alt="Red Ribbon" />
        )}
      </div>
    </footer>
  )
}

export default Footer

const Copyright = ({ className, lang }) => {
  return (
    <div className={`footer-copyright ${className}`}>
      © {moment().year()} BSD City. &nbsp;
      <Link to="/privacy-policy/">
        {lang == "EN" ? "Privacy Policy" : "Kebijakan Privasi."}
      </Link>
      <span className="divider">|</span>
      <Link to="/terms-conditions/">
        {lang == "EN" ? "Terms & Conditions" : "Syarat dan Ketentuan."}
      </Link>
    </div>
  )
}
